import React, { useEffect } from "react"
import "../assets/flip.css"

const ProjectList = (props) => {
  const { currentOrg, allProjects, setAllProjects } = props

  useEffect(() => {
    // console.info(`currentOrg ID is ${props.currentOrg.id}`)
    const fetchData = async () => {
      console.info("currentOrg = " + JSON.stringify(currentOrg))
      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      // const pathname = `projects/${currentOrg.id}`
      const pathname = "projects"
      try {
        const response = await fetch(baseUrl + pathname)
        const result = await response.json()
        setAllProjects(result.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [currentOrg, setAllProjects])

  return allProjects.map((single) => {
    return (
      <li
        className="btn btn-primary"
        key={single.id}
        onClick={() => props.handleSidebarButtonClick(currentOrg.id, single.id)}
      >
        {single.name}
      </li>
    )
  })
}

const Sidebar = (props) => {
  return (
    <>
      <h4 className="text-center mt-4">Current Projects</h4>
      <ul
        id="sidebar-list"
        className="list-group"
      >
        <ProjectList
          currentOrg={props.currentOrg}
          allProjects={props.allProjects}
          setAllProjects={props.setAllProjects}
          handleSidebarButtonClick={props.handleSidebarButtonClick}
        />
      </ul>
      <button
        className="btn btn-md btn-secondary w-100"
        id="editButton"
      >
        Edit
      </button>
    </>
  )
}

export default Sidebar
