/* eslint no-restricted-globals: ["off", { name: "location" }] */
import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/flip.css"
import blue_icon from "./assets/blue-20.png"

import Sidebar from "./components/Sidebar"
// import SlideMenu from "./components/SlideMenu"
import ProjectCard from "./components/ProjectCard"
import EditBar from "./components/EditBar"
import { Toast } from "react-bootstrap"

function App() {
  const [allProjects, setAllProjects] = useState([])
  const [toast, setToast] = useState({ show: false, bg: "info", icon: { blue_icon }, title: "", body: "" })

  let orgToken = "ABC12345"
  if (window.location.pathname !== "/" && location.pathname.length >= 9) {
    const orgIdFromUrl = location.pathname.slice(1, location.pathname.length)
    console.info("Getting Org ID from URL: " + orgIdFromUrl)
    document.cookie = "orgId=" + orgIdFromUrl
    orgToken = orgIdFromUrl
  } else if (document.cookie.length === 14) {
    const orgIdCookie = document.cookie
    console.info(orgIdCookie)
    const orgIdFromCookie = orgIdCookie.split("=")[1]
    orgToken = orgIdFromCookie
  } else {
    console.info(`No ID found. Falling back to default value: ${orgToken}.`)
  }
  console.info("orgToken = '" + orgToken + "'") // TEST: PASS 2023-07-06
  const [currentOrg, setCurrentOrg] = useState({})
  // const [currentProject, setCurrentProject] = useState({})
  // console.info("currentOrg.id = " + currentOrg.id) // TEST: PASS 2023-07-06

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE

    /***        Use orgToken to get full Org Details        ***/
    const fetchOrg = async (orgToken) => {
      console.info("fetchOrg(): orgToken = '" + orgToken + "'")
      // console.info(process.env.NODE_ENV) // 'production' on DH
      // console.info(process.env.REACT_APP_DEV_BACKEND)
      // console.info(process.env.REACT_APP_PROD_BACKEND)

      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      const pathname = `orgs/load/${orgToken}`
      try {
        const response = await fetch(baseUrl + pathname)
        const result = await response.json()
        console.info(JSON.stringify(result))
        setCurrentOrg(result.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchOrg(orgToken)
  }, [orgToken])

  /***    Use Org ID to load initial Project    ***/
  const [currentProject, setCurrentProject] = useState({})
  useEffect(() => {
    const fetchInitProject = async () => {
      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      const pathname = "projects/initial"
      try {
        const response = await fetch(baseUrl + pathname)
        const result = await response.json()
        console.info(result.data)
        setCurrentProject(result.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchInitProject()
  }, [])

  /***    Use orgId to get Project Details for initial display    ***/
  const handleSidebarButtonClick = async (org, id) => {
    console.info("User selected new project with ID: " + id)
    console.info("Loading new project...")

    const fetchData = async (currentOrg, currentProject) => {
      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      // const pathname = `projects/{$currentProject.id}`
      const pathname = "projects"
      try {
        const response = await fetch(baseUrl + pathname)
        const result = await response.json()
        setCurrentProject(result.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData(currentOrg, id)
  }

  return (
    <div className="container-fluid">
      <div className="row">
        {/* <div className="col-1"></div> */}
        <div className="col-2 g-12">
          {/* <SlideMenu
            currentOrg={currentOrg}
            handleSidebarButtonClick={handleSidebarButtonClick}
          /> */}
          <Sidebar
            currentOrg={currentOrg}
            allProjects={allProjects}
            setAllProjects={setAllProjects}
            handleSidebarButtonClick={handleSidebarButtonClick}
          />
        </div>
        <div className="col g-12">
          <ProjectCard
            currentOrg={currentOrg}
            currentProject={currentProject}
          />
        </div>
        <div className="col-2 g-12">
          <EditBar
            currentOrg={currentOrg}
            allProjects={allProjects}
            setAllProjects={setAllProjects}
            toast={toast}
            setToast={setToast}
          />
        </div>
        {/* <div className="col-1"></div> */}
      </div>
      <Toast
        show={toast.show}
        bg={toast.bg}
        onClose={() => setToast({ show: false })}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <img
            src={toast.icon}
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">{toast.show ? toast.title : "TITLE"}</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>{toast.show ? toast.body : "Placeholder"}</Toast.Body>
      </Toast>
    </div>
  )
}

export default App
