import React, { useEffect, useState } from "react"
import "../assets/flip.css"
import bluebg from "../assets/blue-500.png"
import graybg from "../assets/gray-500.png"

const ProjectCard = (props) => {
  const currentOrg = props.currentOrg
  const currentProject = props.currentProject
  const [relatedTasks, setRelatedTasks] = useState([])
  // console.info("currentOrg = " + JSON.stringify(currentOrg))
  // console.info("currentProject = " + JSON.stringify(currentProject))

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      const pathname = `tasks/related/${currentProject.id}`

      try {
        const response = await fetch(baseUrl + pathname)
        const result = await response.json()
        setRelatedTasks(result.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [currentProject])

  const toggleFlip = () => {
    document.getElementById("flipCardDiv").classList.toggle("do-flip")
  }

  return (
    <div className="flip-card-3D-wrapper">
      <h1 className="text-center">{currentOrg.name}</h1>

      <div
        className="flip-card"
        id="flipCardDiv"
      >
        <div
          className="flip-card-front flip-card-btn-turn-to-back"
          onClick={() => toggleFlip()}
        >
          <div className="card-body">
            <div className="card-img">
              <img
                src={bluebg}
                alt="pretty blue background"
              />
            </div>
            <div className="card-img-overlay d-flex flex-column mt-5">
              <h4 className="text-center mt-5">{currentProject.name}</h4>
              <p className="text-center">Status: {currentProject.status}</p>
              <p className="text-center mt-auto">Updated: {currentProject.updated}</p>
            </div>
          </div>
        </div>
        <div
          className="flip-card-back flip-card-btn-turn-to-front"
          onClick={() => toggleFlip()}
        >
          <div className="card-body">
            <div className="card-img">
              <img
                src={graybg}
                alt="dignified gray background"
              />
            </div>
            <div className="card-img-overlay d-flex flex-column mt-5">
              <h4 className="text-center mt-5">{currentProject.name}</h4>
              <p className="card-text text-center">
                Status: {currentProject.status}
                <br />
              </p>
              <ul>
                {relatedTasks.map((task) => {
                  return <li key={task.id}>{task.name}</li>
                })}
              </ul>
              <p
                id="project-updated-back"
                className="card-text text-center mt-auto"
              >
                Updated: {currentProject.updated}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
