import React, { useEffect, useState } from "react"
import "../assets/flip.css"
import blue_icon from "../assets/blue-20.png"
import green_icon from "../assets/green-20.png"
import red_icon from "../assets/red-20.png"

const ProjectList = (props) => {
  const { currentOrg, allProjects, setAllProjects } = props
  console.info("currentOrg = " + JSON.stringify(currentOrg))

  useEffect(() => {
    const fetchData = async () => {
      console.info("currentOrg = " + JSON.stringify(currentOrg))
      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      const pathname = `projects`
      try {
        const response = await fetch(baseUrl + pathname)
        const result = await response.json()
        setAllProjects(result.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [currentOrg, setAllProjects])

  return allProjects.map((single) => {
    return (
      <option
        key={single.id}
        value={single.id}
      >
        {single.name}
      </option>
    )
  })
}

const EditBar = (props) => {
  const { currentOrg, allProjects, setAllProjects, setToast } = props
  // const [itemType, setItemType] = useState("")
  const [newItem, setNewItem] = useState({})

  const handleInputChange = (e) => {
    // console.info(e.target)
    // console.info("Input '" + e.target.id + "' changed to: " + e.target.value)

    // const itemType = e.target.id.substr(0, e.target.id.length - 4)
    // setNewItem({ name: e.target.value, type: itemType })
    setNewItem({ name: e.target.value })
  }

  const handleFormSubmit = async (e) => {
    // TODO: make sure this works:
    // Does it create proper JSON?
    // Does it work with the Laravel API?
    e.preventDefault()
    const form = e.target.form
    const formData = new FormData(form)
    console.debug("Initial form data:")
    for (var pair of formData.entries()) {
      console.debug(pair[0] + ": " + pair[1])
    }
    if (!formData.get("itemName")) {
      console.debug("Form data was null.")
      //TODO: change this to a Toast
      setToast({
        show: true,
        title: "Error",
        body: "Name cannot be blank.",
        bg: "warning",
        icon: { blue_icon }
      })
    } else {
      const baseUrl = "https://project-flipper-api.aimfireready.com/api/v1/"
      // process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_BACKEND : process.env.REACT_APP_DEV_BACKEND
      const pathname = e.target.form.objectType.value
      // setNewItem({ type: e.target.form.objectType.value })

      console.info(`Adding a new ${newItem.type}`)
      console.debug(`Calling: ${baseUrl}${pathname}`)
      console.info(JSON.stringify(newItem))

      // Call backend and get response
      const response = await fetch(baseUrl + pathname, {
        method: "POST",
        // mode: "no-cors", // last resort //No, wait: we're better than that.
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(newItem)
      })
      const result = await response.json()
      console.info("Backend call for New Item: " + result)
      // TODO: update below for REST API
      const singularObject = e.target.form.objectType.value.slice(0, -1)
      const resultOutput = result.status
        ? `Added new ${singularObject}: '${result.values.name}'`
        : `Result: Failed to add new ${singularObject}: '${result.values.name}'`
      //TODO: confirm this aligns with REST API response format
      if (result.status) {
        // if new item was created successfully
        console.info("Added new item: " + JSON.stringify(result.values))
        e.target.form.reset()

        // setItemType and setAllProjects
        if (result.values.target === "projects") {
          // setNewItem({ type: "project" }) // FIX: but why though?
          setAllProjects((allProjects) => [...allProjects, result.data[0]])
        } else {
          // setNewItem({ type: "task" }) // FIX: but why though?
        }

        // inform the User of the result using a Toast
        setToast({
          show: true,
          title: resultOutput,
          body: result.values.name,
          icon: green_icon
        })
      } else {
        console.info("Failed to add new item: " + JSON.stringify(result.values))
        setToast({
          show: true,
          title: resultOutput,
          body: result.values.name,
          icon: red_icon
        })
      }
    }
  }

  return (
    <>
      <h2 className="text-center mt-4">Edit Bar</h2>
      <div
        className="form mb-3"
        id="addTaskFormDiv"
      >
        <h5 className="text-center mt-4">Add New Task</h5>
        <form
          id="taskForm"
          name="addTaskForm"
        >
          <div>
            <input
              name="objectType"
              value="tasks"
              hidden
              readOnly
            />
            <input
              id="itemName"
              name="itemName"
              className="form-field form-control"
              type="text"
              placeholder="Item Name"
              onChange={(e) => {
                handleInputChange(e)
              }}
            />
            <select
              className="form-select"
              id="projectId"
              name="projectId"
              aria-label="Default select example"
            >
              <option>Select Project</option>
              <ProjectList
                // baseUrl={baseUrl}
                currentOrg={currentOrg}
                allProjects={allProjects}
                setAllProjects={setAllProjects}
              />
            </select>
            <button
              type="submit"
              className="btn btn-md btn-success w-100 mt-2"
              onClick={(e) => handleFormSubmit(e)}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div
        className="form"
        id="addProjectFormDiv"
      >
        <h5 className="text-center mt-4">Add New Project</h5>
        <form
          id="projectForm"
          name="addProjectForm"
        >
          <input
            name="objectType"
            value="projects"
            hidden
            readOnly
          />
          <div>
            <input
              id="itemName"
              name="itemName"
              className="form-field form-control"
              type="text"
              placeholder="Enter name"
              onChange={(e) => {
                handleInputChange(e)
              }}
            />
            <button
              type="submit"
              className="btn btn-md btn-success w-100 mt-2"
              onClick={(e) => handleFormSubmit(e)}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div
        id="output"
        className="w-100 border"
      ></div>
    </>
  )
}

export default EditBar
